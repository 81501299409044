@import "./app/libs/sass/theme.scss";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.order-table__line {
  height: 40px;
  display: flex;

  &__title {
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__value {
    font-weight: 400;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.datatable-checkbox input[type="checkbox"]:before {
  border: 2px solid grey;
}

::ng-deep .mat-select-panel {
  max-height: none !important;
}
